const tokens = {
  "color": {
    "black": "#3f3d3c",
    "blackLighten10": "#575757",
    "blackLighten20": "#717171",
    "blackLighten30": "#8a8a8a",
    "blackLighten40": "#a4a4a4",
    "blackLighten50": "#bdbdbd",
    "blackLighten60": "#d7d7d7",
    "blackLighten70": "#f0f0f0",
    "blackLighten80": "#f7f7f7",
    "blackLighten90": "#fcfcfc",
    "blackDisabled": "#a4a4a4",
    "blackPure": "#000000",
    "blue": "#276cf5",
    "blueDarken20": "#004a94",
    "blueDarken10": "#0063c5",
    "blueLighten10": "#3396f9",
    "blueLighten20": "#66b0fa",
    "blueLighten30": "#99cbfc",
    "blueLighten40": "#cce5fd",
    "blueLighten50": "#e5f1fe",
    "creme": "#e6e3df",
    "cremeDarken30": "#a39889",
    "cremeDarken20": "#b9b1a6",
    "cremeDarken10": "#d0cac2",
    "cremeLighten5": "#f1f0ed",
    "cremeLighten10": "#fcfcfc",
    "diligent": "#2F3B4D",
    "diligentLighten10": "#37465B",
    "diligentLighten20": "#455772",
    "diligentLighten30": "#5D7599",
    "diligentLighten70": "#F4F6F8",
    "diligentDarken10": "#202935",
    "green": "#42996d",
    "greenDarken10": "#337554",
    "greenDarken20": "#23523a",
    "greenLighten10": "#5db187",
    "greenLighten20": "#80c1a0",
    "greenLighten30": "#a2d2ba",
    "greenLighten40": "#c4e3d3",
    "greenLighten50": "#e7f3ed",
    "offWhite": "#f0f0f0",
    "orange": "#de4d33",
    "orangeDarken10": "#bf371f",
    "orangeDarken20": "#932b18",
    "orangeDarken30": "#671e11",
    "orangeLighten10": "#e07764",
    "orangeLighten20": "#e99c8e",
    "orangeLighten30": "#f1c1b9",
    "orangeLighten40": "#fae7e3",
    "orangeDisabled": "#e99c8e",
    "orangeHighlight": "#e1624b",
    "purple": "#785cba",
    "purpleDarken10": "#5f449f",
    "purpleDarken20": "#4a347c",
    "purpleLighten10": "#9884c5",
    "purpleLighten20": "#b5a7d5",
    "purpleLighten30": "#d2c9e6",
    "purpleLighten40": "#efecf6",
    "purpleLighten50": "#fbfafd",
    "white": "#ffffff",
    "yellow": "#eaba51",
    "yellowDarken10": "#e5a823",
    "yellowDarken20": "#be8a17",
    "yellowDarken30": "#916911",
    "yellowDarken40": "#63480c",
    "yellowLighten10": "#eccb82",
    "yellowLighten20": "#f3ddae",
    "yellowLighten30": "#f9f0db",
    "gold": "#f0a117",
    "silver": "#cfcfcf",
    "eggplant": "#2e143d",
    "eggplantLighten10": "#4b2164",
    "eggplantLighten20": "#682e8b",
    "eggplantLighten30": "#853AB1",
    "chartColor01": "#cd3c44",
    "chartColor02": "#cb8133",
    "chartColor03": "#eaba51",
    "chartColor04": "#79a551",
    "chartColor05": "#299a7a",
    "chartColor06": "#8c6d68",
    "chartColor07": "#ec848f",
    "chartColor08": "#b9668f",
    "chartColor09": "#785cba",
    "chartColor10": "#2b82bf",
    "chartColor11": "#7ec0cc",
    "chartColor12": "#d1cbc2"
  },
  "space": "8px",
  "spaceSm": "4px",
  "spaceLg": "12px",
  "placeholder": {
    "color": "#717171",
    "fontStyle": "italic"
  },
  "highlight": {
    "active": {
      "noBorder": {
        "boxShadow": "0 0 0 2px #99cbfc",
        "insetBoxShadow": "inset 0 0 0 2px #99cbfc",
        "borderColor": "#276cf5"
      },
      "withBorder": {
        "boxShadow": "0 0 0 1px #276cf5, 0 0 0 3px #99cbfc",
        "insetBoxShadow": "inset 0 0 0 1px #276cf5, inset 0 0 0 3px #99cbfc"
      },
      "nonInteractive": {
        "outline": "2px dotted #8a8a8a"
      }
    },
    "text": {
      "green": {
        "background": "#e7f3ed",
        "font": "#23523a"
      },
      "orange": {
        "background": "#fae7e3",
        "font": "#932b18"
      },
      "yellow": {
        "background": "#f7f3ba",
        "font": "#63480c"
      }
    }
  },
  "textColor": {
    "default": "#3f3d3c",
    "subtle": "#717171",
    "link": "#0063c5",
    "linkHover": "#004a94",
    "icon": "#717171"
  },
  "fontFamily": {
    "default": "\"Lato\", \"Helvetica Neue\", Helvetica, Arial, sans-serif",
    "jp": "\"Lato\", \"Helvetica Neue\", Helvetica, Arial, sans-serif, \"Hiragino Kaku Gothic Pro\", \"ヒラギノ角ゴ Pro W3\", \"ＭＳ Ｐゴシック\", \"MS PGothic\"",
    "kr": "\"Lato\", \"Helvetica Neue\", Helvetica, Arial, sans-serif, \"Malgun Gothic\"",
    "zh": "\"Helvetica Neue\", Helvetica, Arial, sans-serif, 黑體-繁, \"Heiti TC\", 儷黑Pro, \"LiHei Pro\", PMingLiu, 宋体, SimSun"
  },
  "table": {
    "header": {
      "backgroundColor": "#f0f0f0"
    },
    "row": {
      "backgroundColor": "#ffffff"
    },
    "rowEven": {
      "backgroundColor": "#f7f7f7"
    }
  },
  "border": {
    "radius": "3px",
    "color": "#d7d7d7",
    "hoverColor": "#a4a4a4",
    "colorTransition": "border-color 0.2s"
  },
  "button": {
    "borderRadius": "4px"
  },
  "card": {
    "borderRadius": "6px",
    "shadow": "0 0 0 1px rgba(63,61,60,0.05),0 1px 3px 0 rgba(63,61,60,0.15)"
  },
  "overlay": {
    "animationDuration": "700",
    "backdrop": {
      "backgroundColor": "rgba(63,61,60,0.5)"
    }
  },
  "modal": {
    "backgroundColor": "#ffffff",
    "borderRadius": "6px",
    "shadow": "0 8px 12px -4px rgba(63,61,60,0.2),0 1px 3px 0 rgba(63,61,60,0.1)",
    "sizes": {
      "sm": "450px",
      "md": "675px",
      "lg": "900px"
    },
    "margin": "24px",
    "top": "100px",
    "footer": {
      "backgroundColor": "#f7f7f7"
    }
  },
  "checkbox": {
    "sizeSm": "16px",
    "sizeMd": "18px",
    "sizeLg": "24px"
  },
  "radio": {
    "sizeSm": "16px",
    "sizeMd": "20px",
    "sizeLg": "24px"
  },
  "icon": {
    "sizeSm": "20px",
    "sizeMd": "24px",
    "sizeLg": "40px",
    "sizeXlg": "48px"
  },
  "popover": {
    "borderRadius": "6px",
    "shadow": "0 2px 4px rgba(0,0,0,0.1)"
  },
  "counter": {
    "small": "17px",
    "medium": "19px"
  },
  "pill": {
    "smallRadius": "8px / 50%",
    "mediumRadius": "12px / 50%"
  },
  "backgroundColor": {
    "level0": "#f7f7f7"
  },
  "fontSize": {
    "default": "16px"
  },
  "shadow": "0px 1px 5px rgba(0,0,0,0.07),0px 7px 17px rgba(0,0,0,0.1)",
  "diligent": {
    "actionPrimary": "#5D7599",
    "backgroundBase": "#F4F6F8",
    "brandPrimary": "#2F3B4D"
  }
};
export default tokens;